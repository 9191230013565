import React, { useEffect } from 'react'
import Breadcrums from '../../common/breadcrums/Breadcrums'
// img import
import blog1 from '../../assets/img/blogs/blog1.png'
import blog2 from '../../assets/img/blogs/blog2.png'
import blog3 from '../../assets/img/blogsImg/blog4.jpg'
import blog4 from '../../assets/img/blogsImg/blog3.jpg'
// import blog5 from '../../assets/img/blogsImg/blog4.png'
import { Link } from 'react-router-dom'
function Blogs() {
    const breadcrumsTitle = {
        id: '1', title: 'NEWS'
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return (
        <section>
            <div className='container'>
                <div className=''>
                    <Breadcrums  breadcrumsTitle={breadcrumsTitle}/>
                </div>
                <div className='row py-3'>
                    <div className='col-md-4 col-lg-3 col-12'>
                        <div className=''>
                            <picture>
                                <img src={blog1} alt='blogsImg' width={100 + '%'} className='rounded' />
                            </picture>
                        </div>
                    </div>
                    <div className='col-md-5 col-lg-5 col-12'>
                        <h4>How To Customize Power-On Picture For UV-17R?</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <Link className='text-dark'>Learn More</Link>
                    </div>
                    <div className='col-md-3 col-lg-4 col-12'>
                        <div className='border-bottom'>
                            <h5 className='text-decoration-underline'>RECENT POSTS</h5>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row py-3'>
                    <div className='col-md-4 col-lg-3 col-12'>
                        <div className=''>
                            <picture>
                                <img src={blog2} alt='blogsImg' width={100 + '%'} className='rounded' />
                            </picture>
                        </div>
                    </div>
                    <div className='col-md-5 col-lg-5 col-12'>
                        <h4>How To Customize Power-On Picture For UV-17R?</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <Link className='text-dark'>Learn More</Link>
                    </div>
                    <div className='col-md-3 col-lg-4 col-12'>
                        <div className='border-bottom'>
                            <h5 className='text-decoration-underline'>RECENT POSTS</h5>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row py-3'>
                    <div className='col-md-4 col-lg-3 col-12'>
                        <div className=''>
                            <picture>
                                <img src={blog3} alt='blogsImg' width={100 + '%'} className='rounded' />
                            </picture>
                        </div>
                    </div>
                    <div className='col-md-5 col-lg-5 col-12'>
                        <h4>How To Customize Power-On Picture For UV-17R?</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <Link className='text-dark'>Learn More</Link>
                    </div>
                    <div className='col-md-3 col-lg-4 col-12'>
                        <div className='border-bottom'>
                            <h5 className='text-decoration-underline'>RECENT POSTS</h5>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row py-3'>
                    <div className='col-md-4 col-lg-3 col-12'>
                        <div className=''>
                            <picture>
                                <img src={blog4} alt='blogsImg' width={100 + '%'} className='rounded' />
                            </picture>
                        </div>
                    </div>
                    <div className='col-md-5 col-lg-5 col-12'>
                        <h4>How To Customize Power-On Picture For UV-17R?</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <Link className='text-dark'>Learn More</Link>
                    </div>
                    <div className='col-md-3 col-lg-4 col-12'>
                        <div className='border-bottom'>
                            <h5 className='text-decoration-underline'>RECENT POSTS</h5>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blogs
