import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
function Breadcrums({breadcrumsTitle}) {
    return (
        <section className='border'>
            <div className='container '>
                <div>
                    <span >
                        <strong>
                            <Link to={'/'} className='text-decoration-none text-dark'>
                                HOME
                            </Link>
                        </strong>
                    </span>
                    <span className='px-3' >
                        <FaLongArrowAltRight />
                    </span>
                    <span> <strong>
                        
                        <Link to={'/collections'} className='text-decoration-none text-dark'>
                        {breadcrumsTitle?.title}
                            </Link>
                    </strong></span>
                    <span className='px-3' >
                        {breadcrumsTitle?.icon1}
                    </span>
                    <span className='text-muted'><b>{breadcrumsTitle?.productTitle}</b></span>

                </div>

            </div>
        </section>
    )
}

export default Breadcrums
