import React from 'react'
import ContactUs from '../../components/contactUs/ContactUs'

function OemRequest() {
  return (
    <>
      <ContactUs/>
    </>
  )
}

export default OemRequest
