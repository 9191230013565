import React from 'react'
import { Link } from 'react-router-dom';
import { BsCartDashFill } from "react-icons/bs"
// import { AiFillHeart } from "react-icons/ai"
// skick Crausel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Counter from '../../../../../common/counter/Counter';

// css----
// image----

import product1 from '../../../../../assets/img/bestSellingProducts/bestSelling1.jpg'
import product2 from '../../../../../assets/img/bestSellingProducts/bestSelling2.jpg'
import product3 from '../../../../../assets/img/bestSellingProducts/bestSelling3.jpg'
import product4 from '../../../../../assets/img/bestSellingProducts/bestSelling4.jpg'
import product5 from '../../../../../assets/img/bestSellingProducts/bestSelling3.jpg'

const relatedProducts = [
    { id: '1', proUrl: product1, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '2', proUrl: product2, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '3', proUrl: product3, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '4', proUrl: product3, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '5', proUrl: product4, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '6', proUrl: product1, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '7', proUrl: product2, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '8', proUrl: product3, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '9', proUrl: product3, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
    { id: '10', proUrl: product4, productTitle: 'K-Plug Programming Cable', supportOpreting: "Support Windows 7..." },
]
function DetailsRelatedsProducts() {
    var settings = {
        dots: false,
        infinite: true,
        autoplay:true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2,
                    infinite: false,
                    autoplay:true,
                    dots: false,
                    button:false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className='border-bottom'>
                <h5 className='text-decoration-underline'>
                    <strong>
                        ACCESSORIES
                    </strong>
                </h5>
            </div>
            <div className='container py-4 my-3'>
                <Slider {...settings}>
                    {relatedProducts.map((item) => {
                        return <div className='p-2'>
                            <div className='productsCard1'>
                                <figure>
                                    <Link to={'#'}>
                                        <img src={item.proUrl} alt='products' width={65 + '%'} />
                                    </Link>
                                </figure>
                                <div className='productTitle p-1'>

                                    <div className='text-truncate' >
                                        <span className='text-dark'>
                                            <Link to={''} className='text-decoration-none text-truncate'>{item.productTitle}</Link>
                                        </span>
                                    </div>
                                    <div className='price d-flex'>
                                        <span>INR 29996</span>
                                        <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>INR 59996</span>
                                        <div className='sci  d-flex' >
                                            {/* <Counter /> */}
                                            <span className='addToCart' style={{ fontSize: '22px' }}>
                                                <span style={{ backgroundColor: 'blueviolet', color: '#fff', padding: "0px 5px", borderRadius: "50px", position: "relative", left: '23px', bottom: '12px', fontSize: '10px' }}>0</span>
                                                <BsCartDashFill />
                                            </span>
                                            {/* <span className='addToCart'>
                                                    <AiFillHeart />
                                            </span> */}
                                        </div>

                                    </div>
                                    <div>
                                        <span>
                                            {item.supportOpreting}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    })}

                </Slider>
            </div>
        </>
    )
}

export default DetailsRelatedsProducts
