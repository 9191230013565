import React from 'react'
import MultiProductsItems from '../multiProductsItems/MultiProductsItems'
import ProductsContentDetails from '../productsContentDetails/ProductsContentDetails'

function ProductItem() {
  return (
    <div className='row py-5'>
      <MultiProductsItems/>
      <ProductsContentDetails/>
    </div>
  )
}

export default ProductItem
