import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import { BsCartFill, BsEnvelopeOpenFill } from "react-icons/bs"
import { CiUser } from "react-icons/ci"
import { BiChevronDown } from "react-icons/bi"

import "../topHeader/TopHeader.css"
import { Link } from 'react-router-dom';

// react bootstarp ui  import 
import Form from 'react-bootstrap/Form';
// material ui  import 
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/material';
function TopHeader() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Navbar bg="primary" className='p-0' >
            <div className='container d-block'>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex text-white searchBox'>
                        <div className='emailReq'>
                            <span className='p-2'>
                                <Link to='#' className='text-white'><BsEnvelopeOpenFill /></Link>
                            </span>
                            <span className='p-2 emailReqCont'>
                                <Link to='#' className='text-white'>Subscribe to our Email Newsletter</Link>
                            </span>
                        </div>
                        <input placeholder='search' />
                    </div>
                    <div className='d-flex'>
                        <div className='h-100 selectCountry'>
                            <select defaultValue={""} className='h-100'>
                                <option value={"India"}>
                                    India
                                </option>
                                <option value={"China"}>
                                    China
                                </option>
                                <option value={"Nepal"}>
                                    Nepal
                                </option>
                            </select>
                        </div>
                        <div className=' countItemTopCart'>
                            <span className='px-2'><BsCartFill /></span>
                            <span>Cart-$346,67 USD</span>
                        </div>
                        <div>
                            <div className='loginbtn d-flex align-items-center' id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <span className='px-2'><CiUser /></span>
                                <span className='pt-1'> LOG IN </span>
                                <span className='px-2'><BiChevronDown /></span>
                            </div>
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                                className='p-4'
                            >
                                <div className='p-2'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="name@example.com *" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="password.ControlInput1">
                                            <Form.Control type="password" placeholder="password *" />
                                        </Form.Group>
                                        <div className='m-auto fit-to-contentBtn'>
                                            <Button className='m-auto w-100 rounded-0 my-2' variant="contained">Login</Button>
                                        </div>

                                    </Form>
                                    <div className='text-center'>
                                        <span className='bg-info py-1 px-3 rounded-1 text-white ' style={{ fontSize:"10px"}}>FORGOT YOUR PASSWORD ?</span>
                                    </div>
                                    <div className='text-center'>
                                        <span className='' style={{ fontSize:"12px"}}>NO ACCOUNT? CREATE ONE HERE</span>
                                    </div>
                                </div>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}
export default TopHeader
