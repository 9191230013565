import React from 'react'
import Breadcrums from '../../common/breadcrums/Breadcrums'


// import { AiOutlineHeart } from "react-icons/ai";
// import { BiShoppingBag } from "react-icons/bi";
import "react-rater/lib/react-rater.css";
import ProductItem from './productItem/ProductItem';
import ProductionsDescriptions from './productsDescriptions/ProductionsDescriptions';
import { FaLongArrowAltRight } from 'react-icons/fa';

function ProductDetails() {
    const breadcrumsTitle ={
        id:'1', title:'ANALOG', icon1:<FaLongArrowAltRight />,productTitle:'BF-888S [6 Packs + Six-way Charger + Programming Cable]'

    }
    return (
        <div>
            <div className=''>
                <Breadcrums breadcrumsTitle={breadcrumsTitle} />
            </div>
            <div className='container'>
                <ProductItem/>
            </div>
            <section>
                    <ProductionsDescriptions/>
            </section>
        </div>
        
    )
}

export default ProductDetails
