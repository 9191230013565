import React from 'react'
import Ratings from './rating/Rating'
import Counter from '../../../common/counter/Counter'
import { FaShoppingCart } from 'react-icons/fa'
import { PiBagFill } from 'react-icons/pi'
import { Box, Button, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { BiSolidRightArrow } from 'react-icons/bi'
import { PiVideoFill } from 'react-icons/pi'

function ProductsContentDetails() {
    return (
            <div className='col-md-6 col-lg-6 col-12 px-5 border'>
                <span className='d-flex align-items-center' >
                    <Ratings />
                </span>
                <h4 className='productsTitle'>BF-888S [6 Packs + Six-way Charger + Programming Cable]</h4>
                <div className='price'>
                    <span className='text-muted'>Offer Price : <symbol className='text-decoration-line-through'>INR {''}</symbol>8765</span>
                    &nbsp;
                    &nbsp;
                    <span>Price : <symbol>INR {''}</symbol>8765</span>
                    <Box>
                        <nav aria-label="secondary mailbox folders">
                            <List>
                                <ListItem disablePadding className='border my-1'>
                                    <ListItemButton>
                                        <ListItemText primary="[DOWNLOAD] User Manual" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding className='border my-1'>
                                    <ListItemButton component="a" href="#simple-list">
                                        <ListItemText primary="[DOWNLOAD] Programming Software" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </nav>
                    </Box>
                    <div className=''>
                        <span>
                            <strong>QUANTITY</strong>
                        </span>
                        <div className='w-25  p-2' style={{backgroundColor:"#80808087"}}><Counter /></div>
                    </div>
                    <h6 className='p-2'>Descriptions <BiSolidRightArrow /></h6>
                    <div>
                        <ul className='p-0 m-0 ' style={{ listStyle: 'none', color:'gray' }}>
                            <li>
                                <p>▸ Lorem Ipsum is simply dummy </p>
                            </li>
                            <li>
                                <p>▸ text of the printing and typesetting industry. Lorem Ipsum </p>
                            </li>
                            <li>
                                <p>▸ when an unknown printer took a galley of type and scrambled  </p>
                            </li>
                            <li>
                                <p>▸ Why do we use it?
                                    It is a long established fact that a reader  </p>
                            </li>
                        </ul>
                    </div>
                    <Button className='my-4' variant="contained" startIcon={<FaShoppingCart />}>
                        ADD TO CART
                    </Button>
                    <Button className='m-4' variant="outlined" endIcon={<PiBagFill />}>
                        BUY NOW
                    </Button>
                </div>
                <Button className='my-4 w-100' variant="outlined" startIcon={<PiVideoFill />}>
                    Live Demo
                </Button>
            </div>
    )
}

export default ProductsContentDetails
