import React from 'react'
import "./BannerWrpper.css"
import walki1 from "../../../assets/img/bannerImg/Walkie-Talkies-b1.png"
import walki2 from "../../../assets/img/bannerImg/Walkie-Talkies-b2.png"
import walki3 from "../../../assets/img/bannerImg/Walkie-Talkies-b1.png"

function BannerWrpper() {
    return (
        <section className=''>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={walki1} className="d-block w-100" alt="walki1" />
                    </div>
                    <div className="carousel-item">
                        <img src={walki2} className="d-block w-100" alt="walki2" />
                    </div>
                    <div className="carousel-item active">
                        <img src={walki3} className="d-block w-100" alt="walki1" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    )
}

export default BannerWrpper
