import React from 'react'
import { Button } from '@mui/material';
import {  FloatingLabel } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function ContactsForn() {
    return (
        <div className='row '>
            <div className='col-md-12 col-sm-12 col-lg-12 col-12'>
                <div className='text-center p-3 m-2' style={{ fontFamily: 'Anton', letterSpacing: "5px", }}>
                    <h3>WELCOME TO COOPERATE</h3>
                </div>
            </div>
            <div className='col-md-6 col-lg-6 col-12'>
                <Form.Label htmlFor="inputPassword5"><b>NAME *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
                <Form.Label htmlFor="inputPassword5"><b>EMAIL *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
                <Form.Label htmlFor="inputPassword5"><b>MODEL *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
                <Form.Label htmlFor="inputPassword5"><b>ANNUAL REVENUE *</b></Form.Label>
                <FloatingLabel
                    controlId="floatingTextarea"
                    label="Comments"
                    className='rounded-0 mb-3'
                >
                    <Form.Control as="textarea" placeholder="" />
                </FloatingLabel>
                <Form.Label htmlFor="inputPassword5"><b>SHIPING ADDRESS *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'
                />
                <Form.Label htmlFor="inputPassword5"><b>ZIP OR POST CODE *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
            </div>
            <div className='col-md-6 col-lg-6 col-12'>
                <Form.Label htmlFor="inputPassword5"><b>COMPANY NAME *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
                <Form.Label htmlFor="inputPassword5"><b>PHONE NUMBER *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
                <div>
                    <Form.Label htmlFor="inputPassword5"><b>Quantities and Models Interested In *</b></Form.Label>
                </div>
                <div>
                    <Form.Text id="passwordHelpBlock" muted>
                        <Form.Check
                            type={'radio'}
                            
                            id={`radio1`}
                            className='d-inline  me-3'
                        />  
                         More than 1000 units
                    </Form.Text>
                </div>
                <Form.Label htmlFor="inputPassword5"><b>MESSAGE *</b></Form.Label>
                <FloatingLabel
                    controlId="floatingTextarea"
                    label="MESSAGES"
                    className='rounded-0 mb-3'
                >
                    <Form.Control as="textarea" placeholder="" style={{ height: '85px' }} />
                </FloatingLabel>
                <Form.Label htmlFor="inputPassword5"><b>CITY *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
                <Form.Label htmlFor="inputPassword5"><b>COUNTRY *</b></Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className='rounded-0 mb-3'

                />
            </div>
            <div className='col-md-12 col-sm-12 col-lg-12 col-12'>
                <div className='text-center p-3 m-2' style={{ fontFamily: 'Anton', letterSpacing: "5px", }}>
                <Button variant="contained" className='rounded-0 w-50'>Submit</Button>
                </div>
            </div>
        </div>
    )
}

export default ContactsForn
