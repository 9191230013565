import React from 'react'
import Slider from "react-slick";
// material ui 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

// image 

import blog1 from '../../../assets/img/blogsImg/blog1.png'
import blog2 from '../../../assets/img/blogsImg/blog2.jpg'
import blog3 from '../../../assets/img/blogsImg/blog3.jpg'
import blog4 from '../../../assets/img/blogsImg/blog4.jpg'
import blog5 from '../../../assets/img/blogsImg/blog5.jpg'
import ProductsCrums from '../newArrivals/productscrums/ProductsCrums';

const mockData = [
    {
        id: "1",
        headingTittle: "About GMRS, 10 Things You Better Know",
        imgurl: blog1,
        para: "What is GMRS? Whatis the GMRS Frequency range? Do I need a professional license for operate GMRS radio? How to apply for the GMRS license? Do...",
    },
    {
        id: "2",
        headingTittle: "About GMRS, 10 Things You Better Know",
        imgurl: blog2,
        para: "What is GMRS? Whatis the GMRS Frequency range? Do I need a professional license for operate GMRS radio? How to apply for the GMRS license? Do...",
    },
    {
        id: "3",
        headingTittle: "About GMRS, 10 Things You Better Know",
        imgurl: blog3,
        para: "What is GMRS? Whatis the GMRS Frequency range? Do I need a professional license for operate GMRS radio? How to apply for the GMRS license? Do...",
    },
    {
        id: "4",
        headingTittle: "About GMRS, 10 Things You Better Know",
        imgurl: blog4,
        para: "What is GMRS? Whatis the GMRS Frequency range? Do I need a professional license for operate GMRS radio? How to apply for the GMRS license? Do...",
    },
    {
        id: "5",
        headingTittle: "About GMRS, 10 Things You Better Know",
        imgurl: blog5,
        para: "What is GMRS? Whatis the GMRS Frequency range? Do I need a professional license for operate GMRS radio? How to apply for the GMRS license? Do...",
    },
]
const BlogTite = 'BLOGS'
function Blogs() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay:true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section>
            <div className='container-fluid'>
                <div className='border m-3 p-5'>
                    <div className='mb-3'>
                        <ProductsCrums data={BlogTite}/>
                    </div>
                    <Slider {...settings}>

                        {mockData.map((item, i) => {
                            return <Card sx={{ maxWidth: 445 }}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="240"
                                        image={item.imgurl}
                                        alt="green iguana"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div"sx={{fontWeight:"800"}} lg={{padding:" 0 6px"}}>
                                            {item.headingTittle}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.para}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary">
                                        Learn More
                                    </Button>
                                </CardActions>
                            </Card>
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Blogs
