import React from 'react'
import { Link } from 'react-router-dom'
import { BsCartDashFill } from "react-icons/bs"
import './NewArrivals.css'
import product1 from '../../../../assets/img/products/walkip1.jpg'
import product2 from '../../../../assets/img/products/walkip2.jpg'
import product3 from '../../../../assets/img/products/walkip3.jpg'
import product4 from '../../../../assets/img/products/walkip4.png'
import Counter from '../../../../common/counter/Counter'
function NewArrivalsProducts() {
    return (
        <section>
            <div className='container-fluid my-3'>
                <div className='row py-2'>
                    <div className='col-md-3 col-lg-3 col-2'>
                        <div className='productsCard'>
                            <figure>
                                <Link to={'#'}>
                                    <img src={product1} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <div className='text-truncate' >
                                    <span className='text-dark'>
                                        <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                    </span>
                                </div>
                                <div className='price d-flex'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                        <div className='p-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div>
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 col-2'>
                        <div className='productsCard'>
                            <figure>
                                <Link to={'#'}>
                                    <img src={product2} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <div className='text-truncate' >
                                    <span className='text-dark'>
                                        <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                    </span>
                                </div>
                                <div className='price d-flex'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                        <div className='p-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div>
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 col-2'>
                        <div className='productsCard'>
                            <figure>
                                <Link to={'#'}>
                                    <img src={product3} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <div className='text-truncate' >
                                    <span className='text-dark'>
                                        <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                    </span>
                                </div>
                                <div className='price d-flex'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci d-flex' >
                                        <div className='p-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div>
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 col-2'>
                        <div className='productsCard'>
                            <figure>
                                <Link to={'#'}>
                                    <img src={product4} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>
                                <div className='text-truncate' >
                                    <span className='text-dark'>
                                        <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                    </span>
                                </div>
                                <div className='price d-flex'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci d-flex' >
                                        <div className='p-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div>
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default NewArrivalsProducts
