import React from 'react'
// mui import start 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// mui import End 
// image Import 
import cardvisa from '../../assets/img/networkBg/cardvisa.png'
import PoweredBy from '../powereBy/PoweredBy';
function Footer() {
    return (
        <>
            <section className='bg-dark text-light py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className=''>
                                <List
                                    sx={{ width: '100%', }}
                                    aria-label="contacts"
                                >
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="ABOUT US" sx={{ fontWeight: "800" }} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="About us" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Privacy Policy" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className=''>

                                <List
                                    sx={{ width: '100%', }}
                                    aria-label="contacts"
                                >
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="SUPPORT" sx={{ fontWeight: "800" }} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Track My Order" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Warranty" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Shipping" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Payment" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Return" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className=''>

                                <List
                                    sx={{ width: '100%', }}
                                    aria-label="contacts"
                                >
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="COOPRATION" sx={{ fontWeight: "800" }} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Bulk Order" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="Dealer Request" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton>
                                            <ListItemText inset primary="OEM Request" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className=''>
                                <List
                                    sx={{ width: '100%', }}
                                    aria-label="contacts"
                                >
                                    <ListItem className='p-0'>
                                        <img src={cardvisa} alt='visa' width={100 + '%'} />
                                        {/* <ListItemButton>
                                        <ListItemText inset primary="">
                                            <picture>
                                                
                                            </picture>
                                        </ListItemText>
                                    </ListItemButton> */}
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton >
                                            <ListItemText className='p-0' inset primary="Address - Delhi, 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025 " />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem className='p-0'>
                                        <ListItemButton >
                                            <ListItemText className='p-0' inset primary="Phone No - +91-8851746286" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <PoweredBy/>
        </>
    )
}

export default Footer
