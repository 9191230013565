import React from 'react'
import "./App.css"
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import TopHeader from "./common/topHeader/TopHeader";
import Navigation from './common/navigation/Navigation';
import HomesPages from './pages/Home';
import Footer from './common/footer/Footer';
import ContactsByEmail from './components/home/contactByEmail/ContactsByEmail';
import CategoryPages from './pages/categorey';
import ProductDetails from './components/productDetails/ProductDetails';
import OemRequest from './pages/contectUs';
import Blogs from './components/blogs/Blogs';
function App() {
  return (
    <>
      <TopHeader/>
      <Navigation/>
      <Routes>
        <Route path='/' element={<HomesPages/>}/>
        <Route path='/collections' element={<CategoryPages/>}/>
        <Route path='/product-details' element={<ProductDetails/>}/>
        <Route path='/oem-request' element={<OemRequest/>}/>
        <Route path='/blogs' element={<Blogs/>}/>
      </Routes>
      <ContactsByEmail/>
      <Footer/>
    </>
  );
}

export default App;
