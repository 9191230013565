import React from 'react'
import { Layout, theme } from 'antd';
import { Link } from 'react-router-dom'
import { BsCartDashFill } from "react-icons/bs"
import './ProductsCategoryItens.css'
import Counter from '../../../common/counter/Counter';
import product1 from '../../../assets/img/products/walkip1.jpg'
import product2 from '../../../assets/img/products/walkip2.jpg'
import product3 from '../../../assets/img/products/walkip3.jpg'
import product4 from '../../../assets/img/products/walkip4.png'


const { Content } = Layout;
function ProductsCategoryItems() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Content style={{ margin: '24px 16px 0' }}>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                <div className='row'>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <Link to={'/product-details'}>
                                <figure className='text-center'>
                                    <img src={product1} alt='products' width={65 + '%'} />

                                </figure>
                            </Link>
                            <div className='productTitle p-3'>
                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product2} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                        {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product3} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product4} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                        {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product3} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product1} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product4} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product1} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product1} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product3} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-6'>
                        <div className='productsCard1 my-2'>
                            <figure className='text-center'>
                                <Link to={'/product-details'}>
                                    <img src={product4} alt='products' width={65 + '%'} />
                                </Link>
                            </figure>
                            <div className='productTitle p-3'>

                                <Link to={'/product-details'} className='text-decoration-none'>
                                    <div className='text-truncate' >
                                        <span className='text-dark text-hove-pos'>
                                            <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                                        </span>
                                    </div>
                                </Link>
                                <div className='d-flex' id='price'>
                                    <span>INR 29996</span>
                                    <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>INR 59996</span>
                                    <div className='sci  d-flex' >
                                         {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                        <span className='addToCart'>
                                            <BsCartDashFill />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Content >
    )
}

export default ProductsCategoryItems
