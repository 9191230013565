import React from 'react'
import './Network.css'
function Network() {
  return (
    <div className='networkSections'>
      <div className='container py-5'>
        <div className='row'>
            <div className='col-md-4 col-lg-4 col-1'>
                <div className='p-4'>
                <div className='netHead'>
                    <h3>
                        50 + 
                    </h3>
                </div>
                <div className='netHead'>
                    <h5>
                    R & D team
                    </h5>
                </div>
                <div className='netHead'>
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,  it.
                    </p>
                </div>
                </div>
            </div>
            <div className='col-md-4 col-lg-4 col-1'>
                <div className='p-4'>
                <div className='netHead'>
                    <h3>
                    1500 +
                    </h3>
                </div>
                <div className='netHead'>
                    <h5>
                    Number of workers
                    </h5>
                </div>
                <div className='netHead'>
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                    </p>
                </div>
                </div>
            </div>
            <div className='col-md-4 col-lg-4 col-1'>
                <div className='p-4'>
                <div className='netHead'>
                    <h3>
                    Advanced
                    </h3>
                </div>
                <div className='netHead'>
                    <h5>
                    Equipment
                    </h5>
                </div>
                <div className='netHead'>
                    <p>
                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words .
                    </p>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Network
