import React from 'react'
import "./SeriesProducts.css"
import { Button } from '@mui/material'
import walki1 from '../../../assets/img/seriesProducts/series1.png'
import walki2 from '../../../assets/img/seriesProducts/series2.png'
import walki3 from '../../../assets/img/seriesProducts/series3.png'
import walki4 from '../../../assets/img/seriesProducts/series4.png'
function SeriesProducts() {
    return (
        <section className='bgColorSeries'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3 col-lg-3 col-1 p-reletive backProImg1'>
                        <div className='backgroundProBorder'>


                            <div class="scenes" tabindex="0">
                                <div class="scene-1">
                                    {/* <h2 class="scene-title">First Order Stormtroopers</h2> */}
                                    <div className='productImg'>
                                        <figure>
                                            <img src={walki1} alt='pro1' width={100 + '%'} />
                                        </figure>
                                    </div>
                                </div>
                                <div class="scene-2">
                                    <div class="scene-title">
                                        <div className='seriesContent'>
                                            <div className='seriesHeader'>
                                                <h4>
                                                    BF SERIES
                                                </h4>
                                            </div>
                                            <p>
                                                <span>UHF 400-470MHz handheld radio, voice prompt, flash light, 16 channels, 1500mAh Li-ion battery</span>
                                            </p>
                                            <div className='viewMoreBtn'>
                                                <Button variant="contained" className='w-100' >
                                                    View More
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className=' '>
                            <div className='productImg'>
                                <figure>
                                    <img src={walki1} alt='pro1' width={100 + '%'} />
                                </figure>
                            </div>
                            <div className='seriesContent'>
                                <div className='seriesHeader'>
                                    <h4>
                                        BF SERIES
                                    </h4>
                                </div>
                                <p>
                                    <span>UHF 400-470MHz handheld radio, voice prompt, flash light, 16 channels, 1500mAh Li-ion battery</span>
                                </p>
                                <div className='viewMoreBtn'>
                                    <Button variant="contained" className='w-100' >
                                        View More
                                    </Button>
                                </div>

                            </div>
                        </div> */}
                    </div>
                    <div className='col-md-3 col-lg-3 col-1 p-reletive backProImg2'>
                        <div className='backgroundProBorder'>
                            <div class="scenes" tabindex="0">
                                <div class="scene-1">
                                    {/* <h2 class="scene-title">First Order Stormtroopers</h2> */}
                                    <div className='productImg'>
                                        <figure>
                                            <img src={walki2} alt='pro1' width={100 + '%'} />
                                        </figure>
                                    </div>
                                </div>
                                <div class="scene-2">
                                    <div class="scene-title">
                                        <div className='seriesContent'>
                                            <div className='seriesHeader'>
                                                <h4>
                                                    BF SERIES
                                                </h4>
                                            </div>
                                            <p>
                                                <span>UHF 400-470MHz handheld radio, voice prompt, flash light, 16 channels, 1500mAh Li-ion battery</span>
                                            </p>
                                            <div className='viewMoreBtn'>
                                                <Button variant="contained" className='w-100' >
                                                    View More
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 col-1 p-reletive  backProImg3'>
                        <div className='backgroundProBorder'>
                            <div class="scenes" tabindex="0">
                                <div class="scene-1">
                                    {/* <h2 class="scene-title">First Order Stormtroopers</h2> */}
                                    <div className='productImg'>
                                        <figure>
                                            <img src={walki3} alt='pro1' width={100 + '%'} />
                                        </figure>
                                    </div>
                                </div>
                                <div class="scene-2">
                                    <div class="scene-title">
                                        <div className='seriesContent'>
                                            <div className='seriesHeader'>
                                                <h4>
                                                    BF SERIES
                                                </h4>
                                            </div>
                                            <p>
                                                <span>UHF 400-470MHz handheld radio, voice prompt, flash light, 16 channels, 1500mAh Li-ion battery</span>
                                            </p>
                                            <div className='viewMoreBtn'>
                                                <Button variant="contained" className='w-100' >
                                                    View More
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 col-1 p-reletive backProImg4'>
                        <div className='backgroundProBorder'>
                            <div class="scenes" tabindex="0">
                                <div class="scene-1">
                                    {/* <h2 class="scene-title">First Order Stormtroopers</h2> */}
                                    <div className='productImg'>
                                        <figure>
                                            <img src={walki4} alt='pro1' width={100 + '%'} />
                                        </figure>
                                    </div>
                                </div>
                                <div class="scene-2">
                                    <div class="scene-title">
                                        <div className='seriesContent'>
                                            <div className='seriesHeader'>
                                                <h4>
                                                    BF SERIES
                                                </h4>
                                            </div>
                                            <p>
                                                <span>UHF 400-470MHz handheld radio, voice prompt, flash light, 16 channels, 1500mAh Li-ion battery</span>
                                            </p>
                                            <div className='viewMoreBtn'>
                                                <Button variant="contained" className='w-100' >
                                                    View More
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SeriesProducts
