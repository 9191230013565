import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "../navigation/Navigation.css"
import logo from "../../assets/img/iradiosNewLogo.png"
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
function Navigation() {
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt='logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto fw-bold textNavigation">
              <Nav.Link to="/collections">
                ONLINE STORE
                <ArrowDropDownIcon />
                <div className='dropDown '>
                  <Link to="/collections">
                    Analog
                  </Link>
                  <Link to="/collections">
                    GRMS
                  </Link>
                  <Link to="/collections">
                    Licence Free
                  </Link>
                  <Link to="/collections">
                    Digital
                  </Link>
                  <Link to="/collections">
                    Accessories
                  </Link>
                </div>
              </Nav.Link>
              <Link className='text-decoration-none' to="/collections">PRODUCT LIST</Link>
              <Link className='text-decoration-none' to="/oem-request">CONTACT US</Link>
              <Link className='text-decoration-none' to="/collections">DOWNLOAD  </Link>
              <Link className='text-decoration-none' to="/blogs">NEWS</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Navigation
