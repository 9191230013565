import React from 'react'


import BannerWrpper from './bannerWrapper/BannerWrpper'
import SeriesProducts from './seriesProducts/SeriesProducts'
import NewArrivels from './newArrivals/NewArrivels'
import BestSelling from './bestSelling/BestSelling'
import Blogs from './blogsInHome/Blogs'
import Network from './networks/Network'

function Homes() {
  
  return (
    <>
      <BannerWrpper/>   
      <SeriesProducts/>
      <NewArrivels/>
      <BestSelling/>
      <Blogs/>
      <Network/>
      
    </>
  )
}

export default Homes