import { Button } from '@mui/material'
import React from 'react'
import { Container } from 'react-bootstrap'
import './ProductsCrums.css'
import { Link } from 'react-router-dom'
function ProductsCrums({data}) {
    return (
        <section className='bottomShaddow'>
            <Container className='my-2 py-3'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='newArrivelsPro'>
                        <span>
                            <h6 className='m-0'>
                            {data}
                            </h6>
                        </span>
                    </div>
                    <div className='newArrivelsPro'>
                        <Link  className='btnLink' variant="outlined" to="/blogs">
                            View More
                        </Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default ProductsCrums
