import React, { useRef, useState } from 'react';

import styled from "styled-components";

import walkiLarge1 from '../../../assets/img/products/walkipl1.jpg'
import walkiLarge2 from '../../../assets/img/products/walkipl2.jpg'
import walkiLarge3 from '../../../assets/img/products/walkipl3.jpg'
import walkiLarge4 from '../../../assets/img/products/walkipl4.jpg'
import walkiLarge5 from '../../../assets/img/products/walkipl2.jpg'
import walkiLarge6 from '../../../assets/img/products/walkipl3.jpg'

// hover Zoom Functions 

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 50px;
  // border: 1px solid #00adb7;
  border-radius: 15px;
  width:100%;
  :hover {
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.55), 0 14px 18px rgba(0, 0, 0, 0.55);
  }
`;

const Image = styled.img.attrs((props) => ({
  src: props.source
}))``;

const Target = styled(Image)`
  position: absolute;
  left: ${(props) => props.offset.left}px;
  top: ${(props) => props.offset.top}px;
  opacity: ${(props) => props.opacity};
`;

function MultiProductsItems() {
  const [selectedImage, setSelectedImage] = useState(walkiLarge1)
  const [allImage, setSAllImage] = useState([walkiLarge1, walkiLarge2, walkiLarge3, walkiLarge4, walkiLarge6, walkiLarge5])

  // hover Zoom Functions 

  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  const containerRef = useRef(null);

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e) => {
    const targetRect = targetRef.current.getBoundingClientRect();
    const sourceRect = sourceRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
    const yRatio =
      (targetRect.height - containerRect.height) / sourceRect.height;

    const left = Math.max(
      Math.min(e.pageX - sourceRect.left, sourceRect.width),
      0
    );
    const top = Math.max(
      Math.min(e.pageY - sourceRect.top, sourceRect.height),
      0
    );

    setOffset({
      left: left * -xRatio,
      top: top * -yRatio
    });
  };


  return (
    <div className='col-md-6 col-lg-6 col-12 shadow bg-body rounded'>
      <div className='row p-2 '>
        <div className='col-md-2 col-lg-2 col-2'>
          {allImage.map((img) => {
            return <div className='cursor-pointer'>
              <figure>
                <img src={img} alt='zoomPro'
                  onClick={() => { setSelectedImage(img) }}
                  width={100 + '%'}
                  style={img === selectedImage ? { border: 'solid 1px #107ae33d', padding: "5px",  } : {}}
                />
              </figure>
            </div>
          })}
        </div>



        {/*  */}
        <div className='col-md-10 col-lg-10 col-10'>
         <div className='d-flex justify-content-center align-items-center h-100'>
         <Container
            ref={containerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
          >
            <Image ref={sourceRef} alt="source" source={selectedImage} width={100 + '%'} />
            <Target
              ref={targetRef}
              alt="target"
              opacity={opacity}
              offset={offset}
              source={selectedImage}
            />
          </Container>
         </div>
        </div>
        {/*  */}
        
          {/* <figure className=''>
            <img src={selectedImage} alt='zoomPro' width={100 + '%'} />
          </figure> */}
      </div>
    </div>

  )
}

export default MultiProductsItems
